import React from "react";
import { TbHome } from "react-icons/tb";
import broom from "../../../../assests/images/toggleSvg/broom.svg";
import address from "../../../../assests/images/toggleSvg/address-book.svg";
import referral from "../../../../assests/images/toggleSvg/user-group.svg";
import money from "../../../../assests/images/toggleSvg/moneys.svg";
import whiteBroom from "../../../../assests/images/toggleSvg/white-broom.svg";
import whiteMoney from "../../../../assests/images/toggleSvg/white-moneys.svg";
import whiteAdress from "../../../../assests/images/toggleSvg/white-address-book.svg";
import whiteGroup from "../../../../assests/images/toggleSvg/white-user-group.png";
import orderHistory from "../../../../assests/images/toggleSvg/white-moneys.svg"; // Add this line
import whiteOrderHistory from "../../../../assests/images/toggleSvg/white-moneys.svg"; // Add this line
import { useDispatch, useSelector } from "react-redux";
import { controlToggle } from "../../../../redux/features/toggleSlice";
import { Link } from "react-router-dom";

export default function DashboardToggle() {
  const toggle = useSelector((state) => state.toggle.toggle);
  const dispatch = useDispatch();

  return (
    <div className="relative z-[10]">
      <div className="custom-scrollbar w-full overflow-auto scroll-smooth">
        <div className="w-full flex justify-between bg-white p-2 rounded-lg shadow-sm gap-5 max-[1150px]:gap-2 min-w-[1100px]">
          <div
            className={`${
              toggle === 1 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(1))}
          >
            <TbHome color={toggle === 1 ? "white" : "#ADADAD"} size={20} />
            <p className={`${toggle === 1 ? "text-white" : "text-[#ADADAD]"}`}>
              Home
            </p>
          </div>
          <Link
            className={`${
              toggle === 2 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(2))}
          >
            <img src={toggle === 2 ? whiteBroom : broom} alt="Broom" />
            <p className={`${toggle === 2 ? "text-white" : "text-[#ADADAD]"}`}>
              Cleaning Requests
            </p>
          </Link>
          <div
            className={`${
              toggle === 3 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(3))}
          >
            <img src={toggle === 3 ? whiteAdress : address} alt="Address" />
            <p className={`${toggle === 3 ? "text-white" : "text-[#ADADAD]"}`}>
              Saved Address
            </p>
          </div>
          {/* <div
            className={`${
              toggle === 4 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(4))}
          >
            <img src={toggle === 4 ? whiteGroup : referral} alt="Referral" />
            <p className={`${toggle === 4 ? "text-white" : "text-[#ADADAD]"}`}>
              Referral Program
            </p>
          </div> */}
          <div
            className={`${
              toggle === 5 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(5))}
          >
            <img src={toggle === 5 ? whiteMoney : money} alt="Payment" />
            <p className={`${toggle === 5 ? "text-white" : "text-[#ADADAD]"}`}>
              Payment Management
            </p>
          </div>
          <div
            className={`${
              toggle === 6 && "bg-primary-sky"
            } flex items-center justify-center gap-2 rounded-md flex-1 py-2 cursor-pointer`}
            onClick={() => dispatch(controlToggle(6))}
          >
            <img
              src={toggle === 6 ?  whiteGroup : referral}
              alt="Order History"
            />
            <p className={`${toggle === 6 ? "text-white" : "text-[#ADADAD]"}`}>
              Cleaning History
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
