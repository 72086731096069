import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import successImage from '../../assests/3419259.jpg'; // Adjust the path accordingly

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  console.log(orderId)

  useEffect(() => {
    const completeOrder = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await axios.post(
          `https://klean-up-server-hz1y.onrender.com/v1/api/completeOrders/${orderId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log('Order completed successfully');
        } else {
          console.error('Failed to complete order');
        }
      } catch (error) {
        console.error('Error completing order:', error);
      }
    };

    if (orderId) {
      completeOrder();
    }
  }, [orderId]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold">Payment Successful!</h1>
      <img
        src={successImage} // Use the imported local image
        alt="Success"
        className="mt-6 w-60 h-60"
      />
      <p className="mt-4">Thank you for your cleaning order.</p>
      <button
        onClick={() => navigate('/')}
        className="mt-6 bg-green-500 text-white py-2 px-4 rounded"
      >
        Go to Home
      </button>
    </div>
  );
};

export default PaymentSuccess;
