import { createSlice } from "@reduxjs/toolkit";

export const toggleSlice = createSlice({
  name: "toggle",
  initialState: {
    toggle: 1,
    cardType: 1,
    steps: null,
    address: false,
    booking: null,
  },
  reducers: {
    controlToggle: (state, action) => {
      state.toggle = action.payload;
    },
    selectCardType: (state, action) => {
      state.cardType = action.payload;
    },
    paymentSteps: (state, action) => {
      state.steps = action.payload;
    },
    addAddress: (state, action) => {
      state.address = action.payload;
    },
    bookingSteps: (state, action) => {
      state.booking = action.payload;
    },
  },
});

export const {
  controlToggle,
  selectCardType,
  paymentSteps,
  addAddress,
  bookingSteps,
} = toggleSlice.actions;




export default toggleSlice.reducer;


