import { IoIosArrowRoundForward } from "react-icons/io";

export default function BlogCard({ image, aim, text }) {
  return (
    <div className="bg-white shadow-md">
      <div className="w-full h-64 overflow-hidden">
        <img src={image} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="p-3 text-left">
        <p className="font-semibold text-xl inline-block max-w-[220px]">{aim}</p>
        <p className="text-sm max-w-[270px] mt-3" style={{ color: "#434343" }}>
          {text}
        </p>
        <div className="flex items-center gap-2 mt-3" style={{ color: "#292D32" }}>
          <p>Read More</p>
          <IoIosArrowRoundForward size={24} />
        </div>
      </div>
    </div>
  );
}
