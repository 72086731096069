import { everyMonth, onceWeek, oneTime, others, twiceMonth, twiceWeeks } from "../constants/pricings";
import PricingCard from "./pricingCard";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export function RenderOneTime() {

  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/oneTime"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}

export function RenderOnceWeek() {
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/OnceAweek"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderTwiceMonth() {
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/TwiceAmonth"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
     {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderEveryMonth() {
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/EveryMonth"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderOthers() {
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/other"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}
