import { Link } from "react-router-dom";

export default function CustomerType() {
  return (
    <div className="bg-primary-sky  h-screen font-helvetica-neue max-xl:flex max-xl:justify-center max-xl:items-center overflow-hidden">
      <div className="w-1/2 xl:bg-gray-100 flex h-screen items-center justify-center">
        <div className="bg-white  flex flex-col p-20 max-sm:p-4 rounded-xl max-sm:mx-2">
          <div className="mb-10 w-96 max-sm:w-80">
            <p className="text-3xl font-medium text-sky-400 font-helvetica-neue mb-2">
              KleanUp
            </p>
            <p className="">
              Lorem ipsum dolor sit amet consectetur. Feugiat tincidunt sem sem
              nulla consectetur morbi.
            </p>
          </div>
          <div className="border-2 border-gray-200 rounded-lg text-center mb-10 py-10 px-10 ">
            <p className="font-medium text-xl mb-1 ">
              I’m a returning customer
            </p>
            <p className="mb-2 text-lg">Welcome back to Kleanup!</p>
            <button className=" py-2 w-72 rounded-lg text-white  bg-primary-sky text-lg max-sm:w-full">
              Sign in
            </button>
          </div>
          <div className="border-2 border-gray-200 rounded-lg text-center p-10">
            <p className="font-medium text-xl mb-1 ">I’m a new customer</p>
            <p className="mb-2 text-lg">Welcome back to Kleanup!</p>
            <Link to="/auth">
              <button className=" py-2 w-72 rounded-lg text-white  bg-primary-sky text-lg max-sm:w-full">
                Register
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
