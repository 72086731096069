import React, { useState } from "react";
import google from "../../../assests/images/google.svg";
import gmail from "../../../assests/images/gmail.svg";
import facebook from "../../../assests/images/facebook.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MiniLoader from "../../preloader/mini-preloader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { setHasSignIn } from "../../../redux/features/userSlice";
import { useDispatch } from "react-redux";

export default function LoginForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "feranmi.micheal02@gmail.com",
    password: "Ifedolapo@0108",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.email || !formData.password) {
      toast.error("Please enter both email and password.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/loginCustomer",
        formData
      );
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userId", response.data.id);
        localStorage.setItem("token", response.data.token);
      }
      console.log("Login successful:", response.data);
      toast.success("Login successful!");
      dispatch(setHasSignIn(true));
      history("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(
        error.response.data.error || "Login failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center">
      <div className=" font-helvetica-neue w-[500px]">
        <div className="flex justify-center ">
          <p className="text-primary-sky text-[30px] font-medium  mt-[100px] mb-[80px]">
            FreshMclean
          </p>
        </div>
        <div className="mb-[100px]">
          <form>
            <div className="bg-white shadow-md flex flex-col p-5 rounded-md mx-2">
              <ToastContainer />
              <label className="mb-1 text-[14px]">Email</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email "
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Password</label>
              {/* <div className="relative"> */}
              <div className=" mb-5 flex flex-col">
                <input
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="*************"
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                />
                <div className=" ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div>

              {/* <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </button> */}
              {/* </div> */}
              <p className="-mt-4 text-[#4C4C4C] mb-5 mt-2 text-[14px]">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
              <button
                type="submit"
                className="bg-primary-sky text-white py-3 rounded-md text-center"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <MiniLoader /> : "Signin"}
              </button>
            </div>
          </form>

          <div className="flex mt-[40px] text-[12px] max-sm:text-[16px] gap-x-2 gap-y-5 max-sm:flex-col max-sm:mx-2">
            <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md flex gap-x-2 justify-center items-center">
              <img src={google} alt="google" />
              Signin with google
            </button>
            <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md  flex gap-x-2 max-sm:justify-center items-center">
              <img src={facebook} alt="facebook" />
              Signin with Facebook
            </button>
            {/* <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md  flex gap-x-2 justify-center items-center">
              <img src={gmail} alt="gmail" />
              Signup with Gmail
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
