import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    hasSignIn: null,
    hasSignUp: null,
  },
  reducers: {
    setHasSignIn: (state, action) => {
      state.hasSignIn = action.payload;
    },
    setHasSignUp: (state, action) => {
      state.hasSignUp = action.payload;
    },
  },
});

export const { setHasSignIn } = userSlice.actions;

export const selectHasSignIn = (state) => state.user.hasSignIn;
export const selectHasSignUp = (state) => state.user.hasSignUp;
export default userSlice.reducer;
