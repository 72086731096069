import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold">Payment Failed</h1>
      <p className="mt-4">Unfortunately, your payment could not be processed.</p>
      <button
        onClick={() => navigate('/')}
        className="mt-6 bg-red-500 text-white py-2 px-4 rounded"
      >
        Try Again
      </button>
    </div>
  );
};

export default PaymentFailure;
