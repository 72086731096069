export const MenuButton = ({ onOpen }) => {
  // const handleOpen = () => {
  //   onClick(!onOpen);
  //   onTap(!onOpen);
  // };
  // onClick={handleOpen}
  // onTap, onClick,
  return (
    <>
      <div className="menu">
        <div className={`${onOpen ? "bar1-active" : "bar1"} `} />
        <div className={`${onOpen ? "bar2-active" : "bar2"}  `} />
        <div className={`${onOpen ? "bar3-active" : "bar3"}  `} />
      </div>
    </>
  );
};
