import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "../../assests/images/chat.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting form
    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/get-in-touch",
        formData
      );
      if (response.data.success) {
        toast.success("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="font-helvetica-neue gap-20 w-full grid grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 px-4 py-20">
      <div className="w-full flex justify-end xs:justify-center lg:justify-end md:justify-center">
        <div className="max-w-[500px] h-[650px] pt-10 sm:pl-10 xs:pl-3 flex flex-col rounded-xl lg:bg-gray-100">
          <div className="max-lg:text-center">
            <p className="text-lg font-semibold">GET IN TOUCH</p>
            <p className="text-4xl font-bold my-4 inline-block max-w-[450px]">
              We are here to help you with your cleaning
            </p>
            <p className="inline-block max-w-[400px]">
              Lorem ipsum dolor sit amet consectetur. Ut sit elementum euismod
              non viverra aliquam nec. Molestie dolor ut vel dictum massa sed.
            </p>
          </div>
          <div className="ml-auto mt-auto max-lg:hidden">
            <img src={Chat} alt="chat" />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center max-lg:-mt-96">
        <form
          className="flex flex-col w-full gap-5 border-black"
          onSubmit={handleSubmit}
        >
          <input
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg lg:w-3/4 xl:w-1/2"
          />
          <input
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg lg:w-3/4 xl:w-1/2"
          />
          <input
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg lg:w-3/4 xl:w-1/2"
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg lg:w-3/4 xl:w-1/2"
            rows={5}
          ></textarea>
          <button
            type="submit"
            className="bg-primary-sky text-white py-5 rounded-lg text-lg lg:w-3/4 xl:w-1/2"
          >
            {loading ? "...Submitting..." : "Submit now"}{" "}
            {/* Display loader if loading */}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
