import React, { useState,useEffect } from "react";
import google from "../../../assests/images/google.svg";
import gmail from "../../../assests/images/gmail.svg";
import facebook from "../../../assests/images/facebook.svg";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MiniLoader from "../../preloader/mini-preloader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasSignUp,
  setHasSignIn,
} from "../../../redux/features/userSlice";

export default function SignupForm() {
  function navigate(url) {
    window.location.href = url;
  }

  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [referralCodeFromURL, setReferralCodeFromURL] = useState("");

  const dispatch = useDispatch();
  async function auth() {
    const response = await fetch("https://klean-up-server-hz1y.onrender.com/oauthResponse", {
      method: "post",
    });
    const data = await response.json();
    navigate(data.url);
  }

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    postalCode: "",
    phoneNumber: "",
    referralCode: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const crl = params.get('crl');
    if (crl) {
      setReferralCodeFromURL(crl);
      setFormData({ ...formData, referralCode: crl });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.fullname || !formData.email || !formData.password) {
      toast.error("❌ Please fill in all the fields to register.");
      setLoading(false);
      return;
    }

    // Validate password
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
    if (!passwordRegex.test(formData.password)) {
      toast.error(
        "Password must contain at least one uppercase letter, one symbol, and be at least 8 characters long."
      );
      setLoading(false);
      return;
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      setLoading(false);
      return;
    } else {
      setPasswordError("");
    }

    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/createCustomer",
        formData
      );
      console.log("Registration successful:", response.data);
      toast.success("Registration successful!");
      dispatch(setHasSignIn(true));
      history("/login");
    } catch (error) {
      console.error("Registration failed:", error);
      toast.error(
        error.response.data.error ||
          "Registration failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center">
      <div className=" font-helvetica-neue w-[500px]">
        <div className="flex justify-center ">
          <p className="text-primary-sky text-[30px] font-medium  mt-[100px] mb-[40px]">
            FreshMclean
          </p>
        </div>
        <ToastContainer />
        <div className="mb-[100px]">
          <form onSubmit={handleSubmit}>
            <div className="bg-white shadow-md flex flex-col p-5 rounded-md mx-2">
              <label className="mb-1 text-[14px]">Full Name</label>
              <input
                type="text"
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
                placeholder="Enter your full name"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Password</label>
              <div className="mb-8 flex flex-col">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password must contain 8 characters"
                  value={formData.password}
                  onChange={handleChange}
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                />
                <div className="ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div>

              {/* Confirm Password Field */}
              <label className="mb-1 text-[14px]">Confirm Password</label>
              <div className="mb-8 flex flex-col">
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                />
                <div className="ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div>
              {passwordError && (
                <span className="text-red-500 text-sm mt-1">
                  {passwordError}
                </span>
              )}
              {!referralCodeFromURL && (
                <>
                  <label className="mb-1 text-[14px]">
                    Referral Code(Optional)
                  </label>
                  <input
                    type="text"
                    name="referralCode"
                    value={formData.referralCode}
                    onChange={handleChange}
                    placeholder="Enter your referral code"
                    className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
                  />
                </>
              )}
              <label className="mb-1 text-[14px]">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Enter your phone number"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Postal Code</label>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder="Enter your postal code"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <button
                type="submit"
                className="bg-primary-sky text-white py-3 rounded-md text-center"
                disabled={loading}
              >
                {loading ? <MiniLoader /> : "Signup"}
              </button>
            </div>
          </form>

          <div className="flex mt-[40px] text-[12px] max-sm:text-[16px] gap-x-2 gap-y-5 max-sm:flex-col max-sm:mx-2">
            <button
              className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md flex gap-x-2 justify-center items-center"
              onClick={() => auth()}
            >
              <img src={google} alt="google" />
              Signup with google
            </button>
            <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md  flex gap-x-2 max-sm:justify-center items-center">
              <img src={facebook} alt="facebook" />
              Signup with Facebook
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
