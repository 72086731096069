import React from 'react';

const Preloader = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 m-20"></div>
    </div>
  );
};

export default Preloader;
