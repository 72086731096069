import RenderPricings from "../renderPrices";
import ScheduleComp from "./scheduleComp";

export default function Pricings() {
  return (
    <div className="bg-primary-benefit flex justify-center py-20 w-full ">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="text-center flex items-center justify-center flex-col max-xs:px-2">
          <p className="text-4xl font-semibold mb-4">Pricings</p>
          <p
            className="text-blue-500 inline-block max-w-[500px]"
            style={{ color: "#577C95" }}
          >
            Our pricing structure is transparent and competitive. Choose from a
            variety of packages or customize to fit your needs.
          </p>
        </div>
        <div className="flex justify-center items-center">
          <ScheduleComp />
        </div>
      </div>
    </div>
  );
}
