import React, { useState, useEffect } from "react";
import { GoChevronDown } from "react-icons/go";
import { RxChevronUp } from "react-icons/rx";
import { Link } from "react-router-dom";
import Girl from "../../../assests/images/girl.png";
import { GrNotification, GrLogout } from "react-icons/gr";
import { MenuButton } from "./menu";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTokenStatus from "../../hooks/useTokenStatus";
import logo from "../../../assests/images/FreshMClean-logo PNG 2.png"

export default function CustomerNavigation({ onAuthPage, onSignUpPage }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { tokenAvailable } = useTokenStatus();

  const handleOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const history = useNavigate();

  const handleLogout = async () => {
    sessionStorage.clear();
    await toast.success("You have been logged out!");
    setTimeout(() => {
      history("/login");
    }, 1000);
  };

  return (
    <div className="relative ">
      <nav className="flex justify-between h-20 2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4 shadow-sm items-center bg-white">
        <div className="flex gap-5">
          <ToastContainer />
          <Link to="/">
            <img src={logo} alt="freshNklean logo" className="nav-logo-login"/>
          </Link>
          <div className="flex gap-5 max-lg:hidden">
            <Link to="/about">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                About
              </div>
            </Link>
            {/* <div className="relative">
              <div
                className="flex flex-row gap-2 items-center cursor-pointer hover:bg-gray-200 hover:px-3 hover:rounded-lg px-3 rounded-lg"
                onClick={handleToggleDropdown}
              > */}
                <Link to="/price">
                  <p className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                    Pricing
                  </p>
                </Link>
                {/* {isDropdownOpen ? (
                  <GoChevronDown color="black" />
                ) : (
                  <RxChevronUp color="black" />
                )}
              </div>
              {isDropdownOpen && (
                <div
                  className={`bg-white absolute z-10 px-4 py-4 space-y-2 w-[200px] ${
                    isDropdownOpen
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                >
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                </div>
              )}
            </div> */}
            <Link to="/review">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Reviews
              </div>
            </Link>
            {/* <Link to="/contact">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Contact
              </div>
            </Link> */}
            <Link to="/faqs">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                FAQS
              </div>
            </Link>
          </div>
        </div>
        <div>
          {onAuthPage ? (
            onSignUpPage ? (
              <Link to="/login">
                <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                  Login
                </button>
              </Link>
            ) : (
              <Link to="/signup">
                <button className="py-2 px-5 rounded-xl font-medium text-white bg-primary-sky m-0 text-center hover:bg-primary-skyDark hover:shadow-md hover:-translate-y-1 transition duration-300">
                  Sign Up
                </button>
              </Link>
            )
          ) : (
            <div className="flex justify-center items-center gap-x-3 max-lg:hidden">
              {/* <GrNotification size={24} /> */}

              <img
                src={Girl}
                alt=""
                className="w-[40px] h-[40px] rounded-full "
              />
              {tokenAvailable && <GrLogout size={24} onClick={handleLogout} />}
            </div>
          )}
        </div>

        {/* toggle icon */}

        <div className="lg:hidden flex gap-x-5" onClick={handleOpen}>
          <div className="flex justify-center items-center gap-x-3">
            <GrNotification size={24} />
            <img
              src={Girl}
              alt=""
              className="w-[40px] h-[40px] rounded-full "
            />
          </div>
          <MenuButton
            // onTap={setOpenMenu}
            // onClick={setIsMenuOpen}
            onOpen={isMenuOpen}
          />
        </div>
      </nav>
      {isMenuOpen && (
        <div className=" z-[50] shadow-md bg-white absolute w-full px-5 pb-10">
          {" "}
          <div className="flex flex-col gap-5 ">
            <Link to="/about">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                About
              </div>
            </Link>
            <Link to="/price">
              <p className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Pricing
              </p>
            </Link>
            <Link to="/review">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                Reviews
              </div>
            </Link>
            {/* <Link to='/about'>
            <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
              Contact
            </div>
            </Link> */}
            <Link to="/faqs">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                FAQS
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
