import Reliability from "../assests/images/values/reliability.png";
import Customers from "../assests/images/values/customer.png";
import Badge from "../assests/images/values/badge.png";
export const valuesArr = [
  {
    imgSrc: Badge,
    value: "Your ideal cleaner",
    desc: "With our user-friendly platform, you can effortlessly enjoy the choice of picking diverse cleaning services to suit your talord needs and read through reviews of our professional cleaners. In just a few simple clicks, you can re-book the cleaner who best meets your needs and preferences.",
  },
  {
    imgSrc: Reliability,
    value: "Reliable customer service",
    desc: "Our dedicated support team is available Monday through Saturday, from 8 AM to 5 PM, to assist you via email and WhatsApp. We're here to ensure your queries are addressed promptly and thoroughly.",
  },
  {
    imgSrc: Customers,
    value: "Liability insured",
    desc: "Your safety is our top priority. That's why each professional cleaner on our platform is covered by liability insurance of up to €5 million. You can find more details about this coverage in our comprehensive terms and conditions.",
  },
];
