import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/nav";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeft } from "react-icons/bs";

export default function CleanerRegForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",  // New phone number field
    resumeFile: null,
    idProof: null,    // New ID proof image field
    workAuth: null    // New work authorization image field
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] }); // Update respective file field
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const goBack = () => {
    navigate('/membership');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const payload = new FormData();
    payload.append("fullname", formData.fullname);
    payload.append("email", formData.email);
    payload.append("phoneNumber", formData.phoneNumber);
  
    // Append each image with the same key "image"
    if (formData.resumeFile) {
      payload.append("images", formData.resumeFile);
    }
    if (formData.idProof) {
      payload.append("images", formData.idProof);
    }
    if (formData.workAuth) {
      payload.append("images", formData.workAuth);
    }
  
    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/cleaners",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Application successful!");
      setFormData({
        fullname: "",
        email: "",
        phoneNumber: "",
        resumeFile: null,
        idProof: null,
        workAuth: null
      });
    } catch (error) {
      console.error("Application failed:", error);
      toast.error(
        error.response?.data?.error ||
        "Application failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <div>
        <Navigation />
      </div>
      <div className="flex justify-center">
        <div className="font-helvetica-neue w-[500px]">
          <div className="flex justify-center">
            <p className="text-primary-sky text-[30px] font-medium mt-[100px] mb-[40px]">
              Cleaner Application
            </p>
          </div>
          <ToastContainer />
          <div
            className="bg-white w-fit p-2 rounded-lg items-center flex gap-3 shadow-sm cursor-pointer"
            onClick={goBack}
          >
            <BsArrowLeft />
            <p>Back</p>
          </div>
          <div className="mb-[100px]">
            <form onSubmit={handleSubmit}>
              <div className="bg-white shadow-md flex flex-col p-5 rounded-md mx-2">
                <label className="mb-1 text-[14px]">Full Name</label>
                <input
                  type="text"
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                  placeholder="Enter your full name"
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 mb-5"
                />
                <label className="mb-1 text-[14px]">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 mb-5"
                />
                <label className="mb-1 text-[14px]">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 mb-5"
                />
                <label className="mb-1 text-[14px]">Resume/CV (Lebenslauf)</label>
                <div className="mb-8 flex flex-col">
                  <input
                    type="file"
                    name="resumeFile"
                    onChange={handleChange}
                    className="border border-[#CECECE] outline-none rounded-md bg-[#FAFAFA] py-2 px-5 w-full"
                  />
                </div>
                <label className="mb-1 text-[14px]">Identification Proof</label>
                <div className="mb-8 flex flex-col">
                  <input
                    type="file"
                    name="idProof"
                    onChange={handleChange}
                    className="border border-[#CECECE] outline-none rounded-md bg-[#FAFAFA] py-2 px-5 w-full"
                  />
                </div>
                <label className="mb-1 text-[14px]">Work Authorization</label>
                <div className="mb-8 flex flex-col">
                  <input
                    type="file"
                    name="workAuth"
                    onChange={handleChange}
                    className="border border-[#CECECE] outline-none rounded-md bg-[#FAFAFA] py-2 px-5 w-full"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-primary-sky text-white py-3 rounded-md text-center"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}