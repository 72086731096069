/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import phone from "../../../../assests/images/dashboardPayment/cashless-payment.svg";
import PaymentModal from "./paymentModal";
import { useDispatch, useSelector } from "react-redux";
import ShowModal from "../../../muiModal/modal";

export default function Payment() {
  const [isOpen, setIsOpen] = useState(false);
  const paymentModal = useSelector((state) => state.toggle.steps);
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className=" font-helvetica-neue flex justify-center mx-2">
        <div className="bg-white flex flex-col items-center text-center w-[600px] rounded-xl shadow-sm mt-20 mb-[80px] px-[30px] pt-[20px] pb-10 max-md:w-full">
          <img src={phone} alt="" className="w-[150px] my-8" />
          <p className="font-semibold text-[26px] mb-3">Payment Method</p>
          <p className="inline-block text-primary-blurText max-w-[500px]">
            Lorem ipsum dolor sit amet consectetur. Egestas nisi semper orci
            risus. Aenean mattis quam sed vestibulum aliquam laoreet.
          </p>
          <button
            className="block bg-[#3DA5EC] w-fit text-white px-5 py-3 mt-5 rounded-md"
            onClick={() => setIsOpen(!isOpen)}
          >
            Add new payment method
          </button>
        </div>
        <ShowModal
          element={<PaymentModal />}
          isOpen={isOpen}
          onClose={onClose}
        />
      </div>
    </>
  );
}
