import React, { useState, useEffect } from "react";
import ServiceCard from "../serviceCard";
import axios from "axios";

export default function RenderServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllServiceCategories"
      );
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  return (
    <div className="">
      <p className="font-semibold text-3xl font-helvetica-neue mb-12">
        Rendered services
      </p>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 xl:gap-16 lg:gap-5 w-fit max-sm:">
        {services.map((service) => (
          <div key={service._id} className="p-0">
            <ServiceCard image={service.image} type={service.name} price={service.price} range={service.range} />
          </div>
        ))}
      </div>
    </div>
  );
}
