import { createContext, useState } from "react";

export const ToggleCxt = createContext({
  toggleCleanerDetails: false,
  setToggleCleanerDetails: () => null,
});

export function ToggleCtxProvider({ children }) {
  const [toggleCleanerDetails, setToggleCleanerDetails] = useState(false);
  const [toggleRequestCleaning, setToggleRequestCleaning] = useState(false);
  const [toggleRequestProgess, setToggleRequestProgess] = useState(1);
  const [toggleContent, setToggleContent] = useState(1);
  const [toggleRateCleaner, setToggleRateCleaner] = useState(false);
  const [changeNavTopic, setChangeNavTopic] = useState(0);

  const value = {
    toggleCleanerDetails,
    setToggleCleanerDetails,
    toggleRequestCleaning,
    setToggleRequestCleaning,
    toggleRequestProgess,
    setToggleRequestProgess,
    toggleContent,
    setToggleContent,
    toggleRateCleaner,
    setToggleRateCleaner,
    changeNavTopic,
    setChangeNavTopic,
  };
  return <ToggleCxt.Provider value={value}>{children}</ToggleCxt.Provider>;
}
