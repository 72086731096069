export default function getServicePackage(orderRange) {
  let servicePackage;

  switch (orderRange) {
    case "oneTime":
      servicePackage = "One Time";
      break;
    case "OnceAweek":
      servicePackage = "Once a week";
      break;
    case "TwiceAmonth":
      servicePackage = "Twice a month";
      break;
    case "EveryMonth":
      servicePackage = "Every month";
      break;
    case "other":
      servicePackage = "Others";
      break;
    default:
      servicePackage = "Unknown package type";
      break;
  }

  return servicePackage;
}
