import { useDispatch } from "react-redux";
import ModalContainer from "./modalContainer";
import { LiaTimesSolid } from "react-icons/lia";
import { bookingSteps } from "../../redux/features/toggleSlice";
import ShowModal from "../muiModal/modal";
import { useState, useEffect } from "react";
import SuccessModal from "./successModal";
import getServicePackage from "../helpers/getPackage";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import MiniLoader from "../preloader/mini-preloader";
import Paypal from "./Paypal";

export function ModalRequestDetail({
  orderType,
  services,
  orderRange,
  desireDate,
  additionalInformation,
  customerPhoneNumber,
  entranceNumber,
  building,
  houseNumber,
  city,
  paymentMethod,
  streetName,
  postCode,
  serviceQuantity,
  desireTime,
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModalState = () => {
    setIsOpen(!isOpen);
    SuccessModal();
  };

  useEffect(() => {
    async function fetchServices() {
      try {
        // Fetch details for all services in parallel
        const serviceRequests = services.map(service => 
          axios.get(`https://klean-up-server-hz1y.onrender.com/v1/api/service/${service.serviceId}`)
        );
        const responses = await Promise.all(serviceRequests);

        // Map the responses to the actual data
        const fetchedProducts = responses.map(response => response.data);
        setProducts(fetchedProducts);

        // Log all products
        console.log(fetchedProducts);

        // Log service quantities
        const serviceQuantities = services.map(service => service.serviceQuantity);
        console.log(serviceQuantities);
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    }

    fetchServices();
  }, [services]);

  console.log(products);

  const navigate = useNavigate();

  const makePayment = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");

     // Calculate the total price
  const totalPrice = products.reduce((total, product, index) => {
    const quantity = services[index]?.serviceQuantity || 1;
    return total + product.servicePrice * quantity;
  }, 0).toFixed(2);

    const orderData = {
      orderType,
      desireDate,
      desireTime,
      orderRange,
      postCode,
      services,
      streetName,
      paymentMethod,
      city,
      houseNumber,
      building,
      entranceNumber,
      customerPhoneNumber,
      additionalInformation,
    };

    console.log(orderData);

    const orderHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const orderResponse = await fetch("https://klean-up-server-hz1y.onrender.com/v1/api/orders", {
        method: "POST",
        headers: orderHeaders,
        body: JSON.stringify(orderData),
      });

      if (orderResponse.ok) {
        if(paymentMethod === 'Stripe'){
          const orderResult = await orderResponse.json();
          const orderId = orderResult._id;
          const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

          const body = {
            serviceName: products.map(product => product.serviceName),
            servicePrice: products.map(product => product.servicePrice),
            serviceImage: products.map(product => product.serviceImage),
            serviceQuantity: services.map(service => service.serviceQuantity),
            orderId,
          };
          console.log(body,'check');

          const headers = {
            "Content-Type": "application/json",
          };

          const response = await fetch("https://klean-up-server-hz1y.onrender.com/v1/api/checkoutStripe", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          });

          const session = await response.json();

          if (!response.ok) {
            throw new Error(session.error || "Something went wrong");
          }

          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });

          if (result.error) {
            console.log(result.error.message);
          }
        } else {
          return <Paypal/>;
        }
      } else {
        throw new Error("Order creation failed");
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally, you can show an error message to the user here
    } finally {
      setLoading(false);
    }
  };

  const [tasks, setTasks] = useState([
    "Toilet cleaning",
    "Bathroom cleaning",
    "Bedroom cleaning",
    "Window cleaning",
  ]);

  const removeTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  
  return (
    <>
      <div className="font-helvetica-neue">
      <div className="border-b border-[#F0F0F0] pb-5">
  {[
    {
      label: "Type of cleaning",
      value: products.map(product => product.serviceName).join(", "), // Join all product names with a comma separator
    },
    { label: "Package", value: getServicePackage(orderRange) },
    { label: "Scheduled date", value: desireDate },
    { label: "Payment method", value: paymentMethod },
  ].map((d, i) => {
    return (
      <div
        key={i}
        className="flex justify-between mb-3 max-[450px]:flex-col"
      >
        <p>{d.label}</p>
        <p className="font-medium">{d.value}</p>
      </div>
    );
  })}
</div>

        <div className="my-5 border-b pb-5">
          <p className="mb-4">Cleaning equipmentss</p>
          <div className="flex flex-wrap gap-5">
            <p>
              Kindly check the list of equipment needed and on the{' '}
              <Link to="/faqs" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                equipment
              </Link>
            </p>
          </div>
        </div>
        {/* <div className="my-5 border-b pb-5">
          <p className="mb-4">Cleaning sections</p>
          <div className="flex flex-wrap gap-5">
            {tasks.map((task, index) => (
              <div
                key={index}
                className="flex items-center gap-2 border px-2 py-0 bg-[#FAFAFA] rounded-lg border-[#CECECE]"
              >
                <p className="text-sm">{task}</p>
                <LiaTimesSolid onClick={() => removeTask(index)} />
              </div>
            ))}
          </div>
        </div> */}
        <div className="flex gap-5  items-center mt-5 pb-5 border-[#F0F0F0] border-b ">
          <div className="flex-grow">
            <p className="font-medium">Bonus</p>
            <input
              placeholder="Enter your promo code"
              className="border rounded-md outline-none w-full px-2 py-1 text-[14px]"
            />
          </div>
          <button className="w-[100px] text-center py-4 text-white rounded-lg  bg-[#3DA5EC]">
            Apply
          </button>
        </div>
        {products ? (
          <div className=" my-5  bg-[#F6F6F6] rounded-lg p-3 mb-5">
            {[
              {
                label: "Total price",
                // value: "200 €",
              },
              // {
              //   label: "Discount applied",
              //   value: "10%",
              // },
              // {
              //   label: "Total price (with discount)",
              //   value: "180 €",
              // },
            ].map((d, i) => {
              return (
                <div key={i} className="flex justify-between mb-5">
                  <p className="">{d.label}</p>
                  <div className="flex justify-between mb-3 max-[450px]:flex-col">
    <p>Total Price</p>
    <p className="font-medium">
      €{" "}
      {products.reduce((total, product, index) => {
        const quantity = services[index]?.serviceQuantity || 1;
        return total + product.servicePrice * quantity;
      }, 0).toFixed(2)}
    </p>
  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <p>loading</p>
          </div>
        )}

        <button
          className="w-full text-white bg-[#3DA5EC] py-3 rounded-lg"
          // onClick={handleModalState}
          onClick={makePayment}
          disabled={loading}
        >
          {loading ? <MiniLoader /> : "Book cleaning"}
        </button>
      </div>
    </>
  );
}
