import { useEffect, useState } from "react";

export default function Footer() {
  const [year, setYear] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const isDropdownOpen = (index) => openDropdown === index;

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, [year]);
  return (
    <div className="flex flex-col pt-20 pb-32 lg:px-20 xl:px-52 xs:px-4 bg-black">
      <div className="text-white font-helvetica-neue  flex justify-between  md:flex-row xs:flex-col xs:gap-y-10 max-lg:gap-x-16 ">
        <div className="max-md:text-center">
          <p className="font-bold text-2xl mb-4">FreshMClean</p>

          <p className="inline-block max-w-[400px] text-sm">
            Our team of experienced and reliable cleaners is dedicated to
            providing top-notch cleaning services for your home.
          </p>
          <div className="mt-10 flex xs:flex-col md:flex-row ">
            <input
              placeholder="Email"
              className="bg-white md:w-1/2 py-2 px-4 rounded-md md:mr-3  xs-full outline-none text-black flex-grow"
            />
            <button className="bg-primary-sky py-2 px-2 rounded-md max-md:mt-4 ml-auto max-md:w-full">
              Subcribe to our mailing
            </button>
          </div>
        </div>
        <div className="flex gap-x-16 max-md:hidden">
          <div>
            <p className="text-xl font-semibold mb-6">Product</p>
            <p className="text-sm mb-3">Benefits</p>
            <p className="text-sm mb-3">Features</p>
            <p className="text-sm mb-3">Pricing</p>
          </div>
          <div>
            <p className="text-xl font-semibold mb-6">Company</p>
            <p className="text-sm mb-3">About</p>
            <p className="text-sm mb-3">Customers</p>
            <p className="text-sm mb-3">FAQS</p>
            <p className="text-sm mb-3">Contacts</p>
          </div>
          <div>
            <p className="text-xl font-semibold mb-6">Use cases</p>
            <p className="text-sm mb-3">For Cleaner</p>
            <p className="text-sm mb-3">For Clients</p>
          </div>
        </div>
        {/* mini */}
        <div className="flex gap-x-16 md:hidden xs:flex-col items-center">
          <div className="text-center">
            <p className="text-2xl font-medium mb-5">Product</p>
            <p className="">Benefits</p>
            <p>Features</p>
            <p>Pricing</p>
          </div>
          <div className="text-center mt-5">
            <p className="text-2xl font-medium mb-5">Company</p>
            <p>About</p>
            <p>Customers</p>
            <p>FAQS</p>
            <p>Contacts</p>
          </div>
          <div className="text-center mt-5">
            <p className="text-2xl font-medium mb-5">Use Cases</p>
            <p>For Cleaners</p>
            <p>For Clients</p>
            <p></p>
          </div>
        </div>
      </div>
      <div className="mt-11 text-white flex flex-col">
        <hr className="border-top-2 border-gray-700  w-full inset-0 max-md:hidden" />
        <p className="self-center mt-10">© {year} FreshMClean, Inc. </p>
      </div>
    </div>
  );
}
