import React, { useState, useEffect } from "react";
import cleaning from "../../../../assests/images/dashboardReferral/house-cleaning.svg";
import axios from "axios";
import MiniLoader from "../../../preloader/mini-preloader";

export default function Introduction() {
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCustomer();
  }, []);

  const fetchCustomer = async () => {
    const userId = sessionStorage.getItem("userId");
    if (userId) {
      axios
        .get(
          `https://klean-up-server-hz1y.onrender.com/v1/api/getCustomer/${userId}`
        )
        .then((response) => {
          const customer = response.data.customer;
          setFullName(customer.name);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <div className="home-multi-bgColor min-h-[250px] rounded-lg shadow-sm my-20 px-5 pt-5  flex justify-between max-md:flex-col max-md:gap-y-10 max-md:items-center">
        <div className="max-w-[500px] max-md:w-full flex flex-col mr-10 max-md:mr-0 max-md:text-center">
          {loading ? (
            <MiniLoader />
          ) : (
            <>
              <p className="font-semibold mb-3 text-[20px]">
                Hello {fullName},
              </p>
            </>
          )}
          <p className="max-w-[400px] max-md:mx-auto  inline-block text-primary-blurText mb-6 max-md:max-w-[500px]">
            Lorem ipsum dolor sit amet consectetur. Egestas nisi semper orci
            risus. Aenean mattis quam sed vestibulum aliquam laoreet.
          </p>
          <button className="w-[300px] py-2 rounded-md  bg-primary-sky text-white max-md:w-full">
            Place your Cleaning order
          </button>
        </div>
        <div>
          <img src={cleaning} alt="house" />
        </div>
      </div>
    </div>
  );
}
