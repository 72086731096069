import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LiaTimesSolid } from "react-icons/lia";

export default function ShowModal({ element, isOpen, onClose, width = 500 }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "100%" : width,
    bgcolor: "background.paper",
    boxShadow: 10,
    pt: 3,
    px: 2,
    pb: 3,
    borderRadius: 1,
  };
  return (
    <div>
      <Modal open={isOpen} onClose={onClose} sx={{ mx: 2 }}>
        <Box sx={{ ...style }}>
          <div
            className="shadow-sm right-[-10px] top-[-10px] absolute z-[10] w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center"
            onClick={onClose}
          >
            <LiaTimesSolid color="black" size={20} />
          </div>
          <div className="max-sm:w-full">{element}</div>
        </Box>
      </Modal>
    </div>
  );
}
