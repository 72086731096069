import React from "react";
import {
    PayPalScriptProvider,
    BraintreePayPalButtons,
    PayPalButtons,
  } from "@paypal/react-paypal-js";

export default function Paypal() {
  const initialOptions = {
    "client-id": "AdMwJENA9M4f-5USWucgnFmrU080K7f6Lc2DEp_u7j-5FF_XWNY0i2rHM7AAfS1RAaguqf2Y5JgzwCI7",
    currency: "USD",
    intent: "capture",
  };
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: "0.01",
          },
        },
      ],
    });
  };
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      alert("Transaction completed by" + details.payer.name.given_name);
    });
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
        style={{layout:"horizontal"}}
        createOrder={(data,actions)=>createOrder(data,actions)}
        onApprove={(data,actions)=>onApprove(data,actions)}
        />
    </PayPalScriptProvider>
  );
}
