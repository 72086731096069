import React from "react";
import LoginForm from "../../../components/customer-component/login-component/login-form";
import CustomerNavigation from "../../../components/customer-component/customerNav/nav";
import Footer from "../../../components/home-ui-components/footer";
export default function Login() {
  return (
    <div className=" flex flex-col">
      <CustomerNavigation onAuthPage={true} />
      <div className="">
        <LoginForm />
      </div>
      <Footer />
    </div>
  );
}
