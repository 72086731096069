import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsArrowLeft } from "react-icons/bs";
import MiniLoader from '../../../preloader/mini-preloader'
import Preloader from '../../../preloader/preloader'
import { loadStripe } from '@stripe/stripe-js';
import Paypal from '../../../modalView/Paypal';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = sessionStorage.getItem("token");
      try {
        const response = await axios.get('https://klean-up-server-hz1y.onrender.com/v1/api/customersAllOrders', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch orders');
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-700';
      case 'incomplete':
        return 'bg-yellow-100 text-yellow-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };

  const completeOrder = async (orderId, serviceId, serviceQuantity,paymentMethod) => {
    setCompletingOrder(true);
    const token = sessionStorage.getItem("token");
    if(paymentMethod ==='Stripe'){
      try {
        // Fetch product details
        const productResponse = await axios.get(`https://klean-up-server-hz1y.onrender.com/v1/api/service/${serviceId}`);
        if (productResponse.data) {
          setProduct(productResponse.data);
  
          const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
  
          const body = {
            serviceName: productResponse.data.serviceName,
            servicePrice: productResponse.data.servicePrice,
            serviceImage: productResponse.data.serviceImage,
            serviceQuantity,
            orderId,
          };
  
          const headers = {
            "Content-Type": "application/json",
          };
  
          const response = await fetch("https://klean-up-server-hz1y.onrender.com/v1/api/checkoutStripe", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          });
  
          const session = await response.json();
  
          if (!response.ok) {
            throw new Error(session.error || "Something went wrong");
          }
  
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });
  
          if (result.error) {
            console.log(result.error.message);
          }
        } else {
          throw new Error("Failed to fetch product details");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setCompletingOrder(false);
      }
    }else if(paymentMethod==='PayPal'){
      setCompletingOrder(false);
      return <Paypal/>
    }
    
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="bg-white p-6 mt-20 mb-6 rounded shadow-md">
      <h2 className="text-2xl font-bold mb-6">Order History</h2>
      {!selectedOrder ? (
        <div>
          <div className="grid grid-cols-4 bg-gray-200 p-2 rounded mb-2">
            <div className="font-semibold">Order SN</div>
            <div className="font-semibold">Desire Date</div>
            <div className="font-semibold">Order Type</div>
            <div className="font-semibold">Status</div>
          </div>
          {orders.map((order) => (
            <div
              key={order._id}
              className="grid grid-cols-4 border-b py-5 px-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleOrderClick(order)}
            >
              <div>{order._id}</div>
              <div>{new Date(order.desireDate).toLocaleDateString()}</div>
              <div>{order.orderType}</div>
              <div className={`px-2 py-1 rounded ${getStatusClass(order.status)}`}>
                {order.status}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <button
            className="mb-4 text-blue-500 flex items-center"
            onClick={() => setSelectedOrder(null)}
          >
            <BsArrowLeft className="mr-1" />
            Back to Order History
          </button>
          <div className="border p-4 rounded-lg bg-gray-50">
            <h3 className="text-lg font-bold mb-4">Order Details</h3>
            <p className="py-1"><strong>Order Number:</strong> {selectedOrder._id}</p>
            <p className="py-1"><strong>Order Type:</strong> {selectedOrder.orderType}</p>
            <p className="py-1"><strong>Date:</strong> {new Date(selectedOrder.desireDate).toLocaleDateString()}</p>
            <p className="py-1"><strong>Time:</strong> {selectedOrder.desireTime}</p>
            <p className="py-1"><strong>Order Range:</strong> {selectedOrder.orderRange}</p>
            <p className="py-1"><strong>Service Quantity:</strong> {selectedOrder.serviceQuantity}</p>
            <p className="py-1"><strong>Post Code:</strong> {selectedOrder.address.postCode}</p>
            <p className="py-1"><strong>Street Name:</strong> {selectedOrder.address.streetName}</p>
            <p className="py-1"><strong>Payment Method:</strong> {selectedOrder.payment.method}</p>
            <p className="py-1"><strong>City:</strong> {selectedOrder.address.city}</p>
            <p className="py-1"><strong>House Number:</strong> {selectedOrder.address.houseNumber}</p>
            <p className="py-1"><strong>Building:</strong> {selectedOrder.address.building}</p>
            <p className="py-1"><strong>Entrance Number:</strong> {selectedOrder.address.entranceNumber}</p>
            <p className="py-1"><strong>Customer Phone Number:</strong> {selectedOrder.address.customerPhoneNumber}</p>
            <p className="py-1"><strong>Additional Information:</strong> {selectedOrder.address.additonalInformation}</p>
            <p className={`py-1 px-2 rounded ${getStatusClass(selectedOrder.status)}`}><strong>Status:</strong> {selectedOrder.status}</p>
            {selectedOrder.status === 'incomplete' && (
              <button
                onClick={() => completeOrder(selectedOrder._id, selectedOrder.serviceId, selectedOrder.serviceQuantity,selectedOrder.payment.method)}
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                disabled={completingOrder}
              >
                {completingOrder ? <MiniLoader/> : 'Complete Order Now'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
