import Navigation from "../components/nav";
import WomanCleaner from "../assests/images/woman-cleaning.png";
import RenderValue from "../components/renderValue";
import Partners from "../components/home-ui-components/partners";
import ExpBg from "../assests/images/experience-bg.png";
import Footer from "../components/home-ui-components/footer";
// import WomanMop from "../assests/images/mission/woman-mopping.png";
// import ManTools from "../assests/images/mission/man-tools.png";
// import HalfRight from "../assests/images/mission/half-right.png";
// import HalfLeft from "../assests/images/mission/half-left.png";
// import ManClean from "../assests/images/mission/man-cleaning.png";
import Group from "../assests/images/group.png";
import { useNavigate } from "react-router-dom";
import MiniGroup from "../assests/images/miniGroup.png";

export default function Mission() {
  const navigate = useNavigate();
  const handleGetStarted = async()=>{
    const token = sessionStorage.getItem("token");
    if(!token){
      navigate('/login')
    }
    navigate('/dashboard')
  }
  return (
    <div className=" font-helvetica-neue overflow-hidden">
      <div>
        <Navigation />
      </div>
      <div
        className="h-[400px]"
        style={{
          backgroundImage: ` url(${WomanCleaner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="mission-container h-[400px] flex  font-helvetica-neue text-white lg:px-52 sm:px-16 items-center xs:px-4">
          <div className="flex flex-col">
            <button className="px-3 py-1 bg-gray-800 rounded-full mb-2 block w-fit">
              About Us
            </button>

            <p className="text-2xl font-semibold inline-block max-w-[400px] mb-2">
              KleanUp House Cleaning services at your fingertip
            </p>
            <p className="text-base max-w-[500px]" style={{ color: "#bbbbbb" }}>
              we're dedicated to delivering exceptional cleaning services
              tailored to meet your needs. With years of experience and a team
              of skilled professionals, we take pride in creating clean and
              healthy spaces for our clients.
            </p>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col h-[300px] lg:px-52 md:px-16 sm:px-16 xs:px-4 max-md:mt-10 mb-24">
        <div className="w-1/2 flex items-center max-md:justify-center max-md:w-full">
          <p className="text-3xl font-semibold">Our mission</p>
        </div>
        <div
          className="w-1/2 flex flex-col justify-center md:mt-28 xs:mt-6  md:max-w-[400px] text-base max-md:items-center max-md:w-full"
          style={{ color: "#333333" }}
        >
          <p className="mb-7">
            Our mission is simple: to provide top-notch cleaning solutions that
            exceed expectations while ensuring customer satisfaction.
          </p>
          <p>
            We strive to create pristine environments, contributing to your
            comfort and well-being.
          </p>
        </div>
      </div>
      <div className="bg-[#F4FBFD] py-16 flex justify-center font-helvetica-neue">
        <div className="max-w-[900px]">
          <p className="text-[34px] font-medium mb-10"> About us </p>
          <p className="leading-[28px] font-light">
            The values we take to heart are professionalism, reliability and
            convenice. our obligations are fulfilled and we strive to improve
            our services. We meet our customer’s expectations. We are flexible
            and open to long-term cooperation and regular services. Our
            advantage is that we can provide our services to you at any time -
            even in the evening or early morning. You will also see the expected
            cleaning time when you place your order so that you can plan
            exactly. We act immediately - We are able to arrive at your home up
            to 6 hours after reserving the service. Our cleaning company also
            offer a 24/7 management service that will solve all your doubts when
            choosing the service or any problems you encounter. We do everything
            we can to ensure that our customers are satisfied with the results
            of our work. <br />
            We take care of your apartment and you save your valuable time. When
            you return you will be happy about the cleanliness. We employ
            experienced, conscientious and friendly people who you can rely on
            in every situation. FreshMclean is characterized by professionalism
            and a high level of competence with an attractive price offer. Our
            experience has taught us how to work efficiently and which cleaning
            products to use to be effective but not pollute the environment. We
            also select them for the type of surfaces to be cleaned so as not to
            damage them. Thanks to proper care, you can enjoy the longevity of
            your furniture and appliances for longer. The goal of FreshMclean is
            to make life easier for you, your household members or employees.
            Trust us and place your first order.
          </p>
        </div>
      </div>
      <div
        className="h-[1000px] mb-32 max-md:hidden"
        style={{
          backgroundImage: `url(${Group})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div
        className="h-[1000px] mb-32 md:hidden"
        style={{
          backgroundImage: `url(${MiniGroup})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      {/* <div className="flex gap-x-10">
          <img src={WomanMop} alt="" className="h-[600px]" />
          <img src={ManClean} alt="" className=" h-[600px]" />
        </div>
        <div className="flex gap-10">
          <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          <img src={ManTools} alt="" className=" w-full h-[400px]" />
          <img src={HalfRight} alt="" className="h-[400px] w-full" />
        </div> */}

      {/* <div className="grid grid-cols-2 gap-4 w-full my-20"> */}

      {/* <div className="col-span-2 md:col-span-1">
          <img src={WomanMop} alt="" className="h-[600px] w-[900px]" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <img src={ManClean} alt="" className=" h-[600px] w-[1000px]" />
        </div>
        <div className="col-span-2 md:col-span-2 grid grid-cols-3 gap-4">
          <div>
            <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          </div>
          <div>
            <img src={ManTools} alt="" className=" w-full h-[400px]" />
          </div>
          <div>
            <img src={HalfRight} alt="" className="h-[400px] w-full" />
          </div>
        </div> */}
      {/* </div> */}
      <div className="bg-black">
        <RenderValue />
      </div>
      {/* <div className="py-20">
        <Partners />
      </div> */}
      <div
        style={{
          backgroundImage: `url(${ExpBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[550px] px-4 text-center flex flex-col gap-y-10 justify-center items-center"
      >
        <p className="text-white text-3xl font-medium max-w-[400px]">
          Experience world-class cleaning with us
        </p>
        <p className="text-gray-400 max-w-[500px]">
          Experience the difference in cleanliness and service quality with
          KleanUp. Contact us today to discuss your cleaning needs and let us
          make your space shine!
        </p>
        <button className="bg-primary-sky rounded-md px-10 py-3 text-white" onClick={handleGetStarted}>
          Get started
        </button>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
