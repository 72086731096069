// eslint-disable-next-line
import Home from "./pages/home";
import Mission from "./pages/about";
import Question from "./pages/question";
import Review from "./pages/review";
import Price from "./pages/price";
import Schedule from "./pages/schedule";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomerType from "./pages/registration/customerType";
import Registration from "./pages/registration/registration";
import CustomerDashBoard from "./pages/dashboard/customerDashboard";
import BecomeCleaner from "./pages/becomeCleaner";
import SignUp from "./pages/registration/auth/signUp";
import Login from "./pages/registration/auth/login";
import CustomerHome from "./pages/home-dashboard/customer-home";
import PaymentSuccess from "../src/components/modalView/paymentSuccess"
import PaymentFailure from "./components/modalView/paymentFailure";
import CleanerRegForm from './pages/clearnerRegForm'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/review" element={<Review />} />
          <Route path="/faqs" element={<Question />} />
          <Route path="/price" element={<Price />} />
          <Route path="/about" element={<Mission />} />
          <Route path="/contact" element={<Schedule />} />
          <Route path="/authType" element={<CustomerType />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup?crl=${}" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/dashboard" element={<CustomerDashBoard />} /> */}
          <Route path="/membership" element={<BecomeCleaner />} />
          <Route path="/dashboard" element={<CustomerHome />} />
          <Route path="/paymentsuccess/:orderId" element={<PaymentSuccess />} />
          <Route path="/paymentfailure" element={<PaymentFailure />} />
          <Route path="/cleanerRegForm" element={< CleanerRegForm/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
