import Navigation from "../components/nav";
import PriceTag from "../assests/images/price-tag.png";
import Dollar from "../assests/images/dollar.png";
import FAQs from "../components/home-ui-components/faqs";
import Partners from "../components/home-ui-components/partners";
import Footer from "../components/home-ui-components/footer";
import PriceLog from "../components/price-ui-compomnents/price-log";
import MiniDollar from "../assests/images/mini-dollar.png";
import MiniTag from "../assests/images/mini-tag.png";

export default function Price() {
  return (
    <div className="font-helvetica-neue  overflow-hidden">
      <div className="bg-white">
        <Navigation />
      </div>
      <div className="bg-black flex justify-between items-center max-md:hidden">
        <img
          src={Dollar}
          alt=""
          className="max-lg:w-[200px] max-md:w-[150px]"
        />
        <div className="text-white text-center">
          <p className="font-medium text-3xl mb-5 max-md:text-xl">
            Pricing plans
          </p>
          <p className="inline-block max-w-[420px] max-md:text-sm">
            We believe that the true measure of our success lies in the
            experiences and voices of our valued customers.
          </p>
        </div>
        <img
          src={PriceTag}
          alt=""
          className="max-lg:w-[200px] max-md:w-[150px]"
        />
      </div>
      {/* mini */}
      <div className="bg-black text-white md:hidden h-fit">
        <div className="text-white flex justify-center flex-col items-center text-center pt-20">
          <p className="text-white text-2xl font-meduim mb-5">Pricing Plans</p>
          <p className="inline-block max-w-[500px] text-sm">
            We believe that the true measure of our success lies in the
            experiences and voices of our valued customers.
          </p>
        </div>
        <div className="flex flex-col -mt-10">
          <img src={MiniDollar} alt="" className="w-[250px]" />
          <img src={MiniTag} alt="" className="w-[250px] ml-auto -mt-32" />
        </div>
      </div>
      <div className="bg-white flex justify-center py-20" style={{ backgroundColor: "#e2e2e2" }}>
        <PriceLog />
      </div>
      <section>
        <div className="bg-white py-20 xl:px-52 lg:px-20 md:px-24 sm:px-8 xs:px-4">
          <div className="flex justify-between pb-10">
            <p className="text-2xl font-medium">FAQS</p>
            <p className="text-gray-500">See all</p>
          </div>
          <FAQs />
        </div>
        <div className="mt-10 bg-white pt-10">
          <Partners />
        </div>
        <div className="mt-20">
          <Footer />
        </div>
      </section>
    </div>
  );
}
