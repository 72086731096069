import Cleaning from "../assests/images/pricingImages/cleaning.png";
import HouseCleaning from "../assests/images/pricingImages/house-cleaning.png";
import House from "../assests/images/pricingImages/house.png";
import RealEstate from "../assests/images/pricingImages/real-estate.png";

export const pricingArr = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 7EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const oneTime = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const onceWeek = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const twiceMonth = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const everyMonth = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const others = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];
