import Time from "../assests/images/benefitImages/time.png";
import Convenience from "../assests/images/benefitImages/convenience.png";
import Spotless from "../assests/images/benefitImages/spotless.png";
import Trusted from "../assests/images/benefitImages/spotless.png"


export const benefitArr = [
  { imgPath: Spotless },
  { imgPath: Convenience },
  { imgPath: Time },
  { imgPath: Trusted },
];
