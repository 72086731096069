import { benefitArr } from "../../constants/benefit";
import BenefitCard from "../benefitCard";

export default function Benefits() {
  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-10 max-xs:px-4">
      {benefitArr.map((image, index) => (
        <div key={index}>
          <BenefitCard image={image.imgPath} />
        </div>
      ))}
    </div>
  );
}
