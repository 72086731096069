import React from "react";
import CustomerNavigation from "../../customerNav/nav";
import DashboardToggle from "../dashboardToggle";
import Footer from "../../../home-ui-components/footer";
import Referral from "../dashboard-referral/referral";
import Home from "../dashboard-home/home";
import Payment from "../dashboard-payment/payment";
import { useSelector } from "react-redux";
import SaveAddress from "../dashboard-address/saveAddress";
import Schedule from "../../../../pages/schedule";
import OrderHistory from "../dashboard-order-history/OrderHistory"; // Add this line

export default function Index() {
  const toggle = useSelector((state) => state.toggle.toggle);

  return (
    <div>
      <div className="bg-[#F2F8FE] w-full min-h-screen">
        <CustomerNavigation />
        <div className="2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4">
          <div className=" mt-10 ">
            <div className="">
              <DashboardToggle />
            </div>
            <div>
              {toggle === 1 ? (
                <Home />
              ) : toggle === 2 ? (
                <div className="2xl:-mx-48 xl:-mx-20 lg:-mx-18 md:-mx-10 sm:-mx-6 xs:-mx-4 mt-5">
                  <Schedule />
                </div>
              ) : toggle === 3 ? (
                <SaveAddress />
              ) : toggle === 4 ? (
                <Referral />
              ) : toggle === 5 ? (
                <Payment />
              ) : toggle === 6 ? (
                <OrderHistory />
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
