import Navigation from "../components/nav";
import comfyRoom from "../assests/images/living-room.png";
import RenderServices from "../components/home-ui-components/renderServices";
import Benefits from "../components/home-ui-components/renderBenefits";
import Rectangle from "../assests/images/rectangle.png";
import Calculation from "../components/home-ui-components/calculation";
import ResponsiveCalculator from "../components/home-ui-components/responsiveCalculator";
import Pricings from "../components/home-ui-components/pricings";
import Contact from "../components/home-ui-components/contact";
import Blog from "../components/home-ui-components/blog";
import Partners from "../components/home-ui-components/partners";
import FAQs from "../components/home-ui-components/faqs";
import Footer from "../components/home-ui-components/footer";
import Carousel from "../components/carousel";
import Testimonials from "../components/home-ui-components/testimonials";
import BgPng from "../assests/images/bg.png";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate()
  const handleGetStarted = async () => {
    const token = sessionStorage.getItem("token");
    
    if (!token) {
      navigate('/login');
    } else {
      navigate('/dashboard');
    }
  };
  return (
    <div className=" w-full relative bg-primary-bg overflow-hidden">
      <div className="h-60 gradient-container">
        <Navigation />
      </div>
      <section className="xl:px-52 lg:px-20 max-md:px-16 max-sm:px-8 max-xs:px-4">
        <div className="flex flex-col max-md:justify-center max-md:items-start max-xs:items-start max-sm:items-start font-helvetica-neue max-w-[640px] relative bottom-16 xs:bottom-20">
          <div className=" max-md:w-3/4">
            <h1 className="font-semibold headWord mb-6 xs:text-xl small-headword sm:text-2xl md:text-3xl lg:text-4xl inline-block max-w-[700px]">
              House Cleaning services at your fingertip
            </h1>
          </div>
          <div className="pr-12 max-md:pr-2">
            <p className="text-gray-500 max-xs:text-base">
              Lorem ipsum dolor sit amet consectetur. Egestas nisi semper orci
              risus. Aenean mattis quam sed vestibulum aliquam laoreet.
            </p>
          </div>
        </div>
        {/* <div className="max-md:hidden">{<Calculation />}</div> */}
        <div className="md:hidden">
          {/* <ResponsiveCalculator /> */}
        </div>

        <div
          className="h-[900px] max-2xl:mb-[-100px] max-xl:mb-[-200px] max-lg:mb-[-260px] max-md:mb-[-430px] max-sm:mb-[-570px] xl:-mx-52 lg:-mx-20 md:-mx-16 sm:-mx-20 xs:-mx-8 mt-20"
          style={{
            backgroundImage: `url(${comfyRoom})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        ></div>
        <div className="mt-20 flex items-center justify-center max-lg:hidden xl:-mx-52 lg:-mx-20 md:-mx-16 sm:-mx-8">
          <RenderServices />
        </div>
        <div className="lg:hidden text-black xl:px-52 lg:px-32 md:px-16 sm:px-8 xs:px-4 xs:-mx-8">
          <Carousel />
        </div>
      </section>

      <div className="bg-primary-benefit flex flex-col justify-center items-center mt-20 py-10 font-helvetica-neue">
        <p className="mb-20 text-indigo-950 font-medium text-lg">BENEFITS</p>
        <div className="flex justify-center items-center xs:px-4 sm:px-0">
          <Benefits />
        </div>
        {/* <button className="bg-primary-sky text-white px-6 py-2 mt-10 rounded-md font-helvetica-neue text-lg">
          Book a Cleaning
        </button> */}
      </div>
      <div
        className="flex relative bg-indigo-950 h-[1000px]"
        style={{ backgroundRepeat: "no-repeat", backgroundSize: "100%" }}
      >
        <div className="text-white font-helvetica-neue w-[550px] md:pl-10 max-sm:w-[570px] lg:pt-32 lg:pl-20 sm:pl-5 sm:pt-10 xs:pt-6 xs:pl-3">
          <p className="text-lg mb-2 font-medium max-sm:text-sm">
            HOW IT WORKS
          </p>
          <div className="w-3/4 max-sm:w-full mb-4">
            <p className="font-bold text-3xl max-sm:text-xl">
              The Inner Working of Our Service!
            </p>
          </div>
          <p className="text-sm">
            We understand that a clean and well-maintained environment is not
            just a preference but a necesity
          </p>
          <button className="bg-primary-sky text-white px-6 py-2 mt-10 rounded-md font-helvetica-neue text-lg" onClick={handleGetStarted}>
            Book a Cleaning
          </button>
        </div>

        <div className="absolute right-0 flex sm:bottom-0 lg:bottom-28 xs:bottom-0">
          <div className=" text-black font-helvetica-neue align-bottom self-center sm:w-[350px] xs:w-[100%] relative xs:left-52 xs:top-8  sm:left-96 lg:top-16 md:left-96 z-50 w-full">
            <p className="text-xl font-semibold">Book Your Cleaning</p>
            <p style={{ color: "#616161" }} className="xs:text-sm">
              Choose your cleaning type, frequency, and preferred date and time.
            </p>
          </div>
          <img
            src={Rectangle}
            alt=""
            className="relative sm:top-16 sm:w-[500px] sm:h-[500px] lg:w-[800px] lg:h-[800px] md:w-[600px] md:h-[600px] xs:h-[600px]  xs:w-[750px] xs:mb-20 lg:mb-0 xs:top-20"
          />
        </div>
      </div>

      <div className="">
        <Pricings />
      </div>
      <div style={{ backgroundColor: "#F4F8FA" }}>
        <Testimonials />
      </div>
      <div className="w-full flex bg-white">
        <Contact />
      </div>
      <div className="bg-white w-full px-4">
        <Blog />
      </div>
      <div className="bg-white">
        <Partners />
      </div>


      <div className="">
        <Footer />
      </div>
    </div>
  );
}
