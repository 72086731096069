import React, { useState, useEffect } from "react";
import axios from "axios";
import masterCard from "../../../../assests/images/dashboardPayment/Mastercard.svg";
import visa from "../../../../assests/images/dashboardPayment/Visa.svg";
import paypal from "../../../../assests/images/dashboardPayment/PayPal.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectCardType } from "../../../../redux/features/toggleSlice";
import ShowModal from "../../../muiModal/modal";
import CardDetails from "./cardDetails";

function PaymentModal() {
  const card = useSelector((state) => state.toggle.cardType);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const customerId = sessionStorage.getItem("userId");
      try {
        const response = await axios.get(
          `https://klean-up-server-hz1y.onrender.com/v1/api/get-payment-methods/${customerId}`
        );
        setPaymentMethods(response.data.paymentMethods);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  const handleCardSelection = (cardType, cardDetails) => {
    dispatch(selectCardType(cardType));
    setSelectedCard(cardDetails);
  };

  return (
    <>
      <div className="flex flex-col gap-5">
        {paymentMethods.map((method, index) => (
          <div
            key={method._id}
            className={`w-full border ${
              card === index + 1 ? "border-[#3DA5EC]" : "border-[#EEEEEE]"
            } rounded-lg p-2 flex gap-5 items-center`}
            onClick={() => handleCardSelection(index + 1, method)}
          >
            <p>{method.paymentType}</p>
            <img src={method.paymentType === "Stripe" ? masterCard : method.paymentType === "Visa" ? visa : paypal} alt={method.paymentType.toLowerCase()} />
            <div
              className={`rounded-full w-[20px] h-[20px] border ${
                card === index + 1 ? "border-[#3DA5EC]" : "border-[#EEEEEE]"
              }  flex justify-center items-center ml-auto`}
            >
              <div
                className={` ${
                  card === index + 1 ? "bg-[#3DA5EC]" : "bg-[#EEEEEE]"
                }  w-[10px] h-[10px] rounded-full`}
              ></div>
            </div>
          </div>
        ))}
        <button
          className="bg-[#3da5ec] text-white py-2 rounded-md"
          onClick={handleModalState}
        >
          Add card
        </button>
        <button
          className="bg-[#3da5ec] text-white py-2 rounded-md"
          onClick={handleModalState}
        >
          Continue
        </button>
      </div>
      <ShowModal
        element={<CardDetails card={selectedCard} />}
        isOpen={isOpen}
        onClose={handleModalState}
      />
    </>
  );
}

export default PaymentModal;
