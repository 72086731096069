import React from "react";
import CustomerNavigation from "../../../components/customer-component/customerNav/nav";
import Footer from "../../../components/home-ui-components/footer";
import SignupForm from "../../../components/customer-component/signup-component/signup-form";

export default function SignUp() {
  return (
    <div className=" flex flex-col">
      <CustomerNavigation onAuthPage={true} onSignUpPage={true} />
      <div className="">
        <SignupForm />
      </div>
      {/* <div className="mt-1"> */}
      <Footer />
      {/* </div> */}
    </div>
  );
}
