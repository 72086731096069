import { useEffect, useState } from "react";
import axios from "axios";
import ScheduleCard from "./schedule-ui-components/scheduleCard";

export default function RenderSchedule({
  getSelectedProducts,
  selectedService,
  getProductCount,
}) {
  const [services, setServices] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, [selectedService]);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        `https://klean-up-server-hz1y.onrender.com/v1/api/services/${selectedService}`
      );
      setServices(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  const handleSelectedProduct = (id) => {
    setSelectedProductIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds.includes(id);
      if (isSelected) {
        return prevSelectedIds.filter((productId) => productId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  useEffect(() => {
    getSelectedProducts(selectedProductIds);
  }, [selectedProductIds, getSelectedProducts]);

  return (
    <div className="flex justify-center">
      <div className="grid xl:grid-cols-4 lg:grid-cols-2  md:grid-cols-2 xs:grid-cols-1 gap-10 my-20 px-4">
        {services?.map((service) => (
          <div
            onClick={() => handleSelectedProduct(service._id)}
            key={service._id}
          >
            <ScheduleCard
              discount={service.serviceDiscount}
              icon={service.serviceImage}
              amount={service.servicePrice}
              type={service.serviceName}
              id={service._id}
              isSelected={selectedProductIds.includes(service._id)}
              onCount={(value) => getProductCount(service._id, value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
