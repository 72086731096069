import React from 'react';
import Modal from '@mui/material/Modal';

const ReferralModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="referral-modal-title"
            aria-describedby="referral-modal-description"
        >
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                    <h2 id="referral-modal-title" className="text-2xl font-semibold mb-4">
                        Refer a Friend Program
                    </h2>
                    <div id="referral-modal-description" className="space-y-2">
                        <p>The process is simple and rewarding:</p>
                        <ol className="list-decimal list-inside">
                            <li className='mb-4'>Log in to your FreshMclean account and navigate to the "Refer a Friend" section. Here, you will find your unique referral code or link, which you can easily share via social media platforms like Facebook, Instagram, WhatsApp, or email.</li>
                            <li className='mb-4'>Share your referral code or link with your friends or acquaintances, encouraging them to use it when booking their first cleaning session with FreshMclean.</li>
                            <li>Once the customer successfully completes their first cleaning order, you will automatically receive a €20 credit towards your next session with us.</li>
                        </ol>
                    </div>
                    <button
                        className="mt-4 bg-[#3da5ec] text-white py-2 px-4 rounded-md hover:bg-[#3394d6]"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ReferralModal;
