import * as yup from "yup";
import { useFormik } from "formik";
import ShowModal from "../muiModal/modal";
import { ModalRequestDetail } from "../modalView/CleanerModal";
import Card from "../../assests/images/debit-card.png";
import { useState } from "react";

const customerContact = yup.object({
  streetName: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  postCode: yup.string().required("Post code is required. "),
  houseNumber: yup.string().required("House Number is required."),
  building: yup.string().required("Building is required."),
  entranceNumber: yup.string().required("Entrance number is required."),
  phoneNumber: yup.string().required("Phone number is required"),
  name: yup.string().required("Name is required."),
  email: yup
    .string()
    .required("Email is required. ")
    .email("Please enter a valid email address"),
  additionalInformation: yup.string(),
});

export default function Contact({ toggleModal, contacts, paymentMethod }) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);

  };


  const onSubmit = (values) => {
    contacts(values);
    paymentMethod(selectedPaymentMethod);
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      streetName: "",
      city: "",
      postCode: "",
      houseNumber: "",
      building: "",
      entranceNumber: "",
      phoneNumber: "",
      name: "",
      email: "",
      additionalInformation: "",
    },
    validationSchema: customerContact,
    onSubmit,
  });


  return (
    <>
      <div className="flex flex-col items-center justify-center my-20 px-4">
        <div className="text-center mb-10 flex flex-col items-center">
          <p className="text-2xl font-medium max-w-[320px] mb-5">
            Input your address and contact details
          </p>
          <p className="text-gray-500 max-w-[450px]">
            Your cleaner comes fully equipped with all the necessary cleaning
            tools and solutions.
          </p>
        </div>
        <div className="flex  w-full justify-center">
          <form
            className=" border p-5 flex gap-x-10 rounded-md max-lg:flex-col max-lg:w-[600px] max-md:w-full mx-4"
            style={{ borderColor: "#3DA5EC" }}
          >
            <div>
              <div className="mb-5">
                <label className="block mb-1 ">Street Name</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2 w-[300px] max-lg:w-full outline-none rounded-lg"
                  onBlur={handleBlur("streetName")}
                  onChange={handleChange("streetName")}
                  value={values.streetName}
                />
                {errors.streetName && touched.streetName && (
                  <p className="text-[#ff0000]">{errors.streetName}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">Post Code</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400  max-lg:w-full p-2 w-[300px] outline-none rounded-lg"
                  onBlur={handleBlur("postCode")}
                  onChange={handleChange("postCode")}
                  value={values.postCode}
                />
                {errors.postCode && touched.postCode && (
                  <p className="text-[#ff0000]">{errors.postCode}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">Building</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-[300px] outline-none rounded-lg"
                  onBlur={handleBlur("building")}
                  onChange={handleChange("building")}
                  value={values.building}
                />
                {errors.building && touched.building && (
                  <p className="text-[#ff0000]">{errors.building}</p>
                )}
              </div>
            </div>

            <div className="">
              <div className="mb-5">
                <label className="block mb-1 ">City</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-[300px] outline-none rounded-lg"
                  onBlur={handleBlur("city")}
                  onChange={handleChange("city")}
                  value={values.city}
                />
                {errors.city && touched.city && (
                  <p className="text-[#ff0000]">{errors.city}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">House Number</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-[300px] outline-none rounded-lg"
                  onBlur={handleBlur("houseNumber")}
                  onChange={handleChange("houseNumber")}
                  value={values.houseNumber}
                />
                {errors.houseNumber && touched.houseNumber && (
                  <p className="text-[#ff0000]">{errors.houseNumber}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">Entrance Number</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-[300px] outline-none rounded-lg"
                  onBlur={handleBlur("entranceNumber")}
                  onChange={handleChange("entranceNumber")}
                  value={values.entranceNumber}
                />
                {errors.entranceNumber && touched.entranceNumber && (
                  <p className="text-[#ff0000]">{errors.entranceNumber}</p>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="flex w-full justify-center">
          <form
            className=" border p-5 flex flex-col gap-y-10 mt-10 rounded-md w-[680px] max-lg:w-[600px] max-md:w-full mx-4"
            style={{ borderColor: "#3DA5EC" }}
          >
            <div className="">
              <div className="mb-5">
                <label className="block mb-1 ">Name</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="text-[#ff0000]">{errors.name}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1">Contact</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  onBlur={handleBlur("phoneNumber")}
                  onChange={handleChange("phoneNumber")}
                  value={values.phoneNumber}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <p className="text-[#ff0000]">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">Email address</label>
                <input
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  onBlur={handleBlur("email")}
                  onChange={handleChange("email")}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <p className="text-[#ff0000]">{errors.email}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">Additional Information</label>
                <textarea
                  placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  rows={10}
                  cols={8}
                  onBlur={handleBlur("additionalInformation")}
                  onChange={handleChange("additionalInformation")}
                  value={values.additionalInformation}
                ></textarea>
                {errors.additionalInformation &&
                  touched.additionalInformation && (
                    <p className="text-[#ff0000]">
                      {errors.additionalInformation}
                    </p>
                  )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-20 mx-4 bg-sky-50">
        <div className="text-center mb-20">
          <p className="text-2xl font-helvetica-neue font-medium mb-3">
            Select payment method
          </p>
          <p className="text-gray-500 max-w-[470px]">
            Your cleaner comes fully equipped with all the necessary cleaning
            tools and solutions.
          </p>
        </div>
        <div className="flex flex-col w-full justify-center items-center">
          <div className="bg-white shadow-sm pt-10 pl-10 rounded-xl flex flex-col w-[700px] max-md:w-[600px] overflow-hidden max-sm:w-full mb-5">
            <div className="h-[250px]">
              <p className="text-xl font-medium mb-3">Select your payment method</p>
              <p className="max-w-[400px] text-gray-500 mb-5 mr-2">
                Your cleaner comes fully equipped with all the necessary cleaning
                tools and solutions.
              </p>
              <div className="flex gap-x-5">
                <button
                  className={`py-2 px-4 rounded-lg ${selectedPaymentMethod === 'Stripe' ? 'bg-primary-sky text-white' : 'bg-gray-200'}`}
                  onClick={() => handlePaymentMethodChange('Stripe')}
                >
                  Select Stripe
                </button>
              </div>
              <div className="flex gap-x-5 mt-6">
                <button
                  className={`py-2 px-4 rounded-lg ${selectedPaymentMethod === 'PayPal' ? 'bg-primary-sky text-white' : 'bg-gray-200'}`}
                  onClick={() => handlePaymentMethodChange('PayPal')}
                >
                  Select PayPal
                </button>
              </div>
            </div>
            <img src={Card} alt="Card" className="w-[300px] ml-auto self-end" />
          </div>

          <button
            className={`py-3 rounded-lg text-white font-medium mt-20 w-[700px] max-md:w-[600px] max-md:w-full ${values.streetName &&
                values.city &&
                values.postCode &&
                values.houseNumber &&
                values.building &&
                values.entranceNumber &&
                values.phoneNumber &&
                values.name &&
                values.email
                ? 'bg-primary-sky'
                : 'bg-gray-400'
              }`}
            onClick={() => {
              if (
                values.streetName &&
                values.city &&
                values.postCode &&
                values.houseNumber &&
                values.building &&
                values.entranceNumber &&
                values.phoneNumber &&
                values.name &&
                values.email
              ) {
                toggleModal();
                handleSubmit();
              } else {
                alert("Please complete all fields before booking.");
              }
            }}
            disabled={
              !values.streetName ||
              !values.city ||
              !values.postCode ||
              !values.houseNumber ||
              !values.building ||
              !values.entranceNumber ||
              !values.phoneNumber ||
              !values.name ||
              !values.email
            }
          >
            Book Cleaning
          </button>

        </div>
      </div>
    </>
  );
}
