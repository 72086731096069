import { HiOutlineArrowUpRight } from "react-icons/hi2";

const defaultProps = {
  showArrow: true,
};

export default function ServiceCard({
  type,
  image,
  showArrow,
  price,
  range,
  onClick,
}) {
  showArrow = showArrow ?? defaultProps.showArrow;

  return (
    <div
      className="bg-cover flex flex-col items-start justify-between px-6 py-10 rounded-3xl w-[350px] h-[420px] font-helvetica-neue"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${image})`,
      }}
      onClick={onClick}
    >
      <div className="self-end">
        {showArrow && (
          <div className="self-end border border-white h-14 w-14 rounded-full items-center justify-center flex">
            <HiOutlineArrowUpRight color="white" />
          </div>
        )}
      </div>

      <div className="self-start text-white">
        <p className=" font-medium text-xl">{type}</p>
        <div className="bg-white bg-opacity-40 flex w-26 h-8 justify-center items-center rounded-full">
          <p>
            €{price} / {range}
          </p>
        </div>
      </div>
    </div>
  );
}
