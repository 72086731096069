import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { Grid } from "@mui/material";
import dayjs from "dayjs";

const today = dayjs();

export default function ValidationBehaviorView({ onSelectTime, onSelectDate }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateChange = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("YYYY-MM-DD");
      setSelectedDate(newDate);
      onSelectDate(formattedDate);
    }
  };

  const handleTimeChange = (newTime) => {
    if (newTime) {
      const hour24 = newTime.hour();
      const minute = String(newTime.minute()).padStart(2, "0");

      let period = "AM";
      let hour12 = hour24;

      if (hour24 >= 12) {
        period = "PM";
        if (hour24 > 12) {
          hour12 = hour24 - 12;
        }
      } else if (hour24 === 0) {
        hour12 = 12;
      }

      const formattedHour = String(hour12).padStart(2, "0");
      const formattedTime = `${formattedHour}:${minute} ${period}`;
      setSelectedTime(newTime);
      onSelectTime(formattedTime);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        columns={{ xs: 1, lg: 2 }}
        spacing={0}
        alignItems="center"
        justifyContent="center"
        gap={10}
      >
        <Grid
          item
          className={`bg-white w-[400px] h-[368px] m-10 rounded-lg border-2 ${selectedDate ? "border-blue-500" : ""}`}
        >
          <DateCalendar
            value={selectedDate || today}
            onChange={handleDateChange}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          className={`bg-white h-[368px] border-2 rounded-lg mb-0 ${selectedTime ? "border-blue-500" : ""}`}
          style={{ paddingBottom: 0 }}
        >
          <DigitalClock
            value={selectedTime || today}
            onChange={handleTimeChange}
            timeStep={60}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
