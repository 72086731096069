import React from "react";
import Navigation from "../components/nav";
import livingroom from "../assests/images/modern-livingroom.svg";
import { IoMdCheckmark } from "react-icons/io";
import Footer from "../components/home-ui-components/footer";
import one from "../assests/images/01.png";
import two from "../assests/images/02.png";
import three from "../assests/images/03.png";
import four from "../assests/images/04.png";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function BecomeCleaner() {
  const location = useLocation();
  const history = useNavigate();
  const handleNext =()=>{
    history('./cleanerRegForm')
  }
  return (
    <div>
      <div>
        <Navigation />
      </div>
      <ToastContainer/>
      <div className="flex text-center font-helvetica-neue mt-20 justify-center items-center">
        <div>
          <p className="text-[46px] font-bold">Welcome to FreshMclean</p>
          <p className="inline-block max-w-[700px] text-[#5B5B5B] text-[18px] mt-5">
            At FreshMclean, we believe in creating a workplace that values its
            team members as much as it values its clients. Joining our cleaning
            team means becoming a crucial part of a company committed to
            excellence and growth.{" "}
          </p>
        </div>
      </div>
      <div className="my-20 w-full flex items-center justify-center">
        <img src={livingroom} alt="" className="w-[90%]" />
      </div>
      <div className="flex justify-center text-[30px]  mb-20">
        <p className="max-w-[500px] inline-block font-bold">
          Here's why you should consider being a part of the Kleanup family:
        </p>
      </div>
      <div className="flex flex-col gap-y-10 mb-20">
        <div className="flex justify-between gap-10">
          <div className="bg-[#EAF4FB] w-fit py-20 px-20 rounded-r-[100px] flex-1">
            <div>
              <p className="text-[26px] font-semibold mb-5">Fulfilling Work</p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Contribute to creating clean and organized spaces that make a
                  real difference in people's lives.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Experience the satisfaction of seeing the immediate impact of
                  your work on our clients' environments.
                </div>
              </p>
            </div>
          </div>

          <div className="bg-[#EAFBED] w-fit py-20 px-20 rounded-l-[100px] flex-1 flex flex-col">
            <div className="">
              <p className="text-[26px] font-semibold mb-5">
                Competitive Compensation
              </p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Enjoy a competitive and fair compensation package that
                  recognizes your hard work and dedication.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Benefit from performance-based incentives and bonuses as you
                  excel in your role.
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-10">
          <div className="bg-[#F0EAFB] w-fit py-20 px-20 rounded-r-[100px] flex-1">
            <div>
              <p className="text-[26px] font-semibold mb-5">
                Supportive Team Environment:
              </p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Join a team of dedicated and friendly professionals who foster
                  a positive and collaborative work environment.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Receive continuous support and encouragement from your
                  colleagues and supervisors.
                </div>
              </p>
            </div>
          </div>

          <div className="bg-[#FBFAEA] w-fit py-20 px-20 rounded-l-[100px] flex-1">
            <div>
              <p className="text-[26px] font-semibold mb-5">
                Training and Development:
              </p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Access comprehensive training programs to enhance your skills
                  and knowledge in cleaning techniques and customer service.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Opportunities for professional growth and advancement within
                  the company.
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-10">
          <div className="bg-[#FBEAEA] w-fit py-20 px-20 rounded-r-[100px] flex-1">
            <div>
              <p className="text-[26px] font-semibold mb-5">
                Employee Benefits:
              </p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Enjoy a range of employee benefits, including health
                  insurance, wellness programs, and other perks.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Participate in team-building activities and events that
                  strengthen the bond among team members.
                </div>
              </p>
            </div>
          </div>

          <div className="bg-[#FBEAEA] w-fit py-20 px-20 rounded-l-[100px] flex-1">
            <div>
              <p className="text-[26px] font-semibold mb-5">Fulfilling Work</p>
              <p className="flex items-center gap-4">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Your hard work and commitment will be recognized and
                  appreciated through regular acknowledgments and awards.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] font-light">
                  Be a part of a company culture that values and celebrates the
                  contributions of its team members.
                </div>
              </p>
            </div>
          </div>
        </div>
        <Link className="bg-primary-sky mx-20 text-white py-3 rounded-lg my-10 text-center" to='/cleanerRegForm'>
          Apply Now
        </Link>
      </div>
      <div className="flex flex-col gap-y-5 mb-20 mx-20">
        <p className="mx-auto font-bold text-[24px]">How it works</p>
        <div className="self-start flex gap-x-10">
          <img alt="one" src={one} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Fill in the application</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-ceter">
          <img alt="two" src={two} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Interview Scheduling</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div className="self-start flex gap-x-10 items-ceter">
          <img alt="three" src={three} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Onboarding</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-ceter">
          <img alt="four" src={four} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Welcome to kleanup</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
