export default function ValueCard({ image, value, desc }) {
  return (
    <div className="bg-white shadow-md max-w-[350px] h-[400px] flex flex-col justify-center text-center items-center rounded-md px-6">
      <div>
        <img src={image} alt="" className="w-[80px] h-[80px] mb-5" />
      </div>
      <div>
        <p className="text-xl font-medium mb-3">{value}</p>
        <p className="max-h-[150px]" style={{ color: "#494949" }}>
          {desc}
        </p>
      </div>
    </div>
  );
}
