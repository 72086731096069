import React from "react";
import copy from "../../../../assests/images/dashboardReferral/copy.svg";
import program from "../../../../assests/images/dashboardReferral/program.svg";
import Introduction from "../dashboard-component/introduction";

export default function Referral() {
  return (
    <div className="flex flex-col font-helvetica-neue">
      <Introduction />
      <div className="flex max-md:flex-col max-md:gap-y-10  justify-between bg-[#E9FCFB] border border-[#11A499] shadow-sm rounded-xl px-10 pt-5 max-sm:px-5">
        <div className="flex flex-col ">
          <p className="text-primary-blurText text-[14px]">
            Referral Bonus Balance
          </p>
          <p className="font-semibold text-[40px]">20 Euros</p>
        </div>
        {/* desktop display */}

        <div className="max-md:hidden">
          <img src={program} alt="people" />
        </div>

        <div className="max-md:hidden">
          <p className="text-primary-blurText text-[14px]">Referral link</p>
          <p className="font-medium text-[26px]">83JD028MS53</p>
          <div className=" rounded-full border border-[#11A499] gap-x-1 flex px-2 justify-center items-center bg-white w-fit ">
            <img src={copy} alt="copy icon" />
            <p className="text-[14px] text-primary-blurText">copy link</p>
          </div>
        </div>

        {/* inversion */}
        <div className="md:hidden flex flex-col">
          <div>
            <p className="text-primary-blurText text-[14px]">Referral link</p>
            <p className="font-medium text-[26px]">83JD028MS53</p>
            <div className=" rounded-full border border-[#11A499] gap-x-1 flex px-2 justify-center items-center bg-white w-fit ">
              <img src={copy} alt="copy icon" />
              <p className="text-[14px] text-primary-blurText">copy link</p>
            </div>
          </div>
          <div className="ml-auto mt-5">
            <img src={program} alt="people" />
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="bg-white w-[500px] max-sm:w-full mx-2 self-center mt-10 flex flex-col p-5 mb-20 shadow-sm rounded-xl">
        <p className="mx-auto mb-10 font-semibold text-[24px]">How it works</p>
        <div className=" flex flex-col gap-4 ">
          <button className="bg-[#ECF3F8] py-3 rounded-lg">
            Send your link to your friend
          </button>
          <button className="bg-[#ECF3F8] py-3 rounded-lg">
            Your friend makes his first order
          </button>

          <button className="bg-[#ECF3F8] py-3 rounded-lg">
            You get <span className="font-semibold">10 Euros</span> sharp!
          </button>
        </div>
      </div>
    </div>
  );
}
