import { configureStore } from "@reduxjs/toolkit";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import userSlice from "./features/userSlice";
import toggleReducer from "./features/toggleSlice";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, countReducer);

export const store = configureStore({
  reducer: {
    toggle: toggleReducer,
    user: userSlice,
  },
});

// export const persistor = persistStore(store)
