import { valuesArr } from "../constants/value";
import ValueCard from "./valueCard";

export default function RenderValue() {
  return (
    <div className="flex flex-col justify-center items-center pt-20 pb-32  font-helvetica-neue px-4">
      <p className="text-white text-[34px] font-medium">
        Advantages of using FreshMclean
      </p>
      <div className="grid md:grid-cols-3 xs:grid-cols-1 pt-20 gap-x-10 gap-y-8">
        {valuesArr.map((value, index) => {
          return (
            <div key={index}>
              <ValueCard
                image={value.imgSrc}
                value={value.value}
                desc={value.desc}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
