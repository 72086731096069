import { pricingArr } from "../../constants/pricings";
import BestPrice from "../../assests/images/best-price.png";
import { priceArr } from "../../constants/price";

export default function PriceLog() {
  return (
    <div>
      <table className=" font-helvetica-neue bg-white max-lg:hidden">
        <thead>
          <tr>
            <th
              className=" text-white flex flex-col justify-between pl-5 pr-3 pt-5 h-[250px] w-[250px]"
              style={{ backgroundColor: "#3DA5EC" }}
            >
              <p className="max-w-[140px] text-xl inline-block text-medium text-left">
                Choose your plan
              </p>
              <img src={BestPrice} alt="" className="w-[150px] ml-auto" />
            </th>
            {pricingArr.map((p, index) => (
              <th
                key={index}
                className=" text-black p-3 text-center h-[250px] w-[250px]"
              >
                <img src={p.imgSrc} alt="" className="w-12 h-12 mx-auto mb-4" />
                <div>
                  <p className="text-base font-medium mb-2">{p.type}</p>
                  <p className="text-2xl font-medium inline-block mb-6 text-[34px]">
                    <span className=" font-normal">€</span>
                    {p.price}
                    <p className="inline-block text-sm text-gray-500">
                      /Hour
                    </p>{" "}
                  </p>
                </div>
                <button className="px-5 py-2 bg-primary-sky text-white rounded-md">
                  Pay now
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {priceArr.map((p, index) => (
            <tr className="" key={index}>
              <td className=" p-3 text-center">
                <p className="inline-block text-lg">{p.service}</p>{" "}
              </td>
              <td className="w-[250px]">
                <div className="w-full flex justify-center">{p.icon1}</div>
              </td>
              <td className="w-[250px]">
                <div className="w-full flex justify-center">{p.icon2}</div>
              </td>
              <td className="w-[250px]">
                <div className="w-full flex justify-center">{p.icon3}</div>
              </td>
              <td className="w-[250px]">
                <div className="w-full flex justify-center">{p.icon4}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* mini */}
      <div className="lg:hidden">
        <div
          className="h-[250px] w-[250px] text-white flex flex-col justify-between pl-5 pr-3 pt-5"
          style={{ backgroundColor: "#3DA5EC" }}
        >
          <p className="inline-block max-w-[120px] text-xl">Choose your plan</p>

          <img src={BestPrice} alt="" className="w-[120px] ml-auto" />
        </div>
        <div className="text-black h-[250px] w-[250px] flex justify-center flex-col items-center  mt-32">
          {priceArr.map((p, index) => {
            return (
              <div key={index} className="mb-4">
                <p>{p.service}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
