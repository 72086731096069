import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import location from "../../../../assests/images/dashboardAddress/web-address 1.svg";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../../redux/features/toggleSlice";
import MiniLoader from "../../../preloader/mini-preloader";

export default function SaveAddress() {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newAddress, setNewAddress] = useState({
    streetName: "",
    city: "",
    postCode: "",
    houseNumber: "",
    building: "",
    entranceNumber: "",
  });

  const saveAddress = useSelector((state) => state.toggle.address);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchCustomerData = async () => {
    const customerId = sessionStorage.getItem("userId");
    try {
      setLoading(true);
      const response = await axios.get(
        `https://klean-up-server-hz1y.onrender.com/v1/api/getCustomer/${customerId}`
      );
      setCustomer(response.data.customer);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const customerId = sessionStorage.getItem("userId");
    try {
      setLoading(true);
      await axios.put(
        `https://klean-up-server-hz1y.onrender.com/v1/api/customerAddress/${customerId}`,
        newAddress
      );
      // Optionally, you can refresh the data or update the local state here
      setIsAddingNew(false);
      dispatch(addAddress(false));
      fetchCustomerData();
    } catch (error) {
      console.error("Error updating address:", error);
    }finally {
      setLoading(false);
    }
  };

  const addressInfo = customer?.addressInformation;

  console.log(addressInfo)
  return (
    <>
      {saveAddress ? (
        <>
          {loading ? (
            <div className="flex justify-center items-center my-20">
              <MiniLoader />
            </div>
          ) : addressInfo && !isAddingNew ? (
            <div className="flex flex-col gap-10 justify-center items-center my-20 font-helvetica-neue">
              <div className="w-[800px] max-[810px]:w-full mx-2 flex flex-col gap-10">
                {/* <div
                  className="bg-white w-fit p-2 rounded-lg items-center flex gap-3 shadow-sm cursor-pointer"
                  onClick={() => dispatch(addAddress(false))}
                >
                  <BsArrowLeft />
                  <p>Back</p>
                </div> */}
                <div className="flex flex-col p-5 rounded-xl shadow-sm bg-white gap-6">
                  <div className="w-fit">
                    <div className="mb-2">Available location</div>
                    <div className="border border-[#CECECE] bg-[#FAFAFA] w-fit px-4 py-1 rounded-xl">
                      {addressInfo.city}
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Street name</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.streetName}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>House number</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.houseNumber}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Post code</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.postCode}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>City</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.city}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Entrance number</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.entranceNumber}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Building</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      value={addressInfo.building}
                      readOnly
                    />
                  </div>
                  {/* <button className="bg-[#3DA5EC] p-3 text-white rounded-md">
                    Edit address
                  </button> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-10 justify-center items-center my-20 font-helvetica-neue">
              <div className="w-[800px] max-[810px]:w-full mx-2 flex flex-col gap-10">
                <div
                  className="bg-white w-fit p-2 rounded-lg items-center flex gap-3 shadow-sm cursor-pointer"
                  onClick={() => {
                    setIsAddingNew(false);
                    dispatch(addAddress(false));
                  }}
                >
                  <BsArrowLeft />
                  <p>Back</p>
                </div>
                <div className="flex flex-col p-5 rounded-xl shadow-sm bg-white gap-6">
                  <div className="flex flex-col gap-3">
                    <label>Street name</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="streetName"
                      value={newAddress.streetName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>House number</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="houseNumber"
                      value={newAddress.houseNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Post code</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="postCode"
                      value={newAddress.postCode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>City</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="city"
                      value={newAddress.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Entrance number</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="entranceNumber"
                      value={newAddress.entranceNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <label>Building</label>
                    <input
                      className="border border-[#CECECE] bg-[#FAFAFA] p-3 rounded-xl"
                      name="building"
                      value={newAddress.building}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button
                    className="bg-[#3DA5EC] p-3 text-white rounded-md"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading?'loading...':'Save address'}
                    
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex mx-2 justify-center items-center rounded-xl px-5 my-20 bg-white shadow-sm py-[50px] font-helvetica-neue">
          <div className="flex flex-col gap-7 items-center">
            <img src={location} alt="location" className="w-[150px]" />
            <p className="text-[36px] font-bold max-sm:text-[24px]">
              No new addresses yet
            </p>
            <button
              className="w-fit bg-[#3DA5EC] py-3 px-10 rounded-lg text-white"
              onClick={() => {
                setIsAddingNew(true);
                dispatch(addAddress(true));
              }}
            >
              Add new address
            </button>
          </div>
        </div>
      )}
    </>
  );
}
