import { IoMdCheckmark } from "react-icons/io";
import { GoDash } from "react-icons/go";

export const priceArr = [
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <IoMdCheckmark color="green" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <GoDash color="#CCCCCC" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <GoDash color="#CCCCCC" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
  {
    service: "Lorem Ipsum",
    icon1: <GoDash color="#CCCCCC" size={26} />,
    icon2: <IoMdCheckmark color="green" size={26} />,
    icon3: <IoMdCheckmark color="green" size={26} />,
    icon4: <IoMdCheckmark color="green" size={26} />,
  },
];
